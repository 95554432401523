import { Main } from "./components/Main";

import { UserNotFound } from "./components/UserNotFound";

const AppRoutes = [
    {
        index: true,
        path: '',
        element: <Main />
    },
    {
        path: 'UserNotFound',
        element: <UserNotFound />
    },
];

export default AppRoutes;
