import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { getApiUrl } from '../ApiUtil'

const code = window.location.search.split('=')[1];
//This code was originally written for OAL, and uses class based react instead of hook based like the rest of SSCM
//Convert this to hook based, very low priority
export class Callback extends Component {
    //runs code to get the token from PingFederate and to check if the app is in maintenance mode
    static displayName = Callback.name;
    componentDidMount() {
        this.retrieveToken(code);
    }
    objectToQuery = (object) => {
        return new URLSearchParams(object).toString();
    };

    //when loading the case manager after logging in, will display this loading screen
    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '48px',
                }}
            >
                Loading Travel Authorization Site...
            </div>
        );
    }
    //uses the code from PingFederate to validate the log in, if the code is valid it uses the redirect url to send the user back to the appropriate URL for SSCM 
    async retrieveToken(code) {
        //this block of code is present in every front end webpage that makes a call to the back end and is neccessary for the code to run locally
        //this code can be refactored to cut down on redundent code
        const apiUrl = getApiUrl();

        try {
            console.log("fetching using code: " + code);
            const response = await fetch(`${apiUrl}/auth/` + code);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            else {
                let in30Minutes = 1 / 48;
                let payload = await response.json();
                Cookies.set('access_token', payload?.access_token, {
                    expires: in30Minutes
                });
                Cookies.set('refresh_token', payload?.refresh_token, {
                    expires: in30Minutes
                });
                Cookies.set('id_token', payload?.id_token, {
                    expires: in30Minutes
                });

                if (window.location.href.includes("localhost")) {
                    window.location.replace(process.env.REACT_APP_LOCAL_REDIRECT_URL);

                } else if (window.location.href.includes("test")) {
                    window.location.replace(process.env.REACT_APP_REDIRECT_URL);
                } else if (window.location.href.includes("stage")) {
                    window.location.replace(process.env.REACT_APP_STAGING_REDIRECT_URL);
                }
                else {
                    window.location.replace(process.env.REACT_APP_PRODUCTION_REDIRECT_URL);
                }
            }
        }
        catch (genericError) {
            console.error(genericError);
        }
    }

}


