import React, { useState, useEffect } from 'react';
import './custom.css';
import { getApiUrl } from './ApiUtil';
import Cookies from 'js-cookie';

export function UserNotFound() {


    return (
        <div >
            <h2 id="banner"></h2>
            <h2 className="authTitle">Authorization to Travel While Absent from Work</h2>
            <div className="divider"></div>
            <div className="travel-authorization-form">
                <h3>Your user id was not found in the database. </h3>
                <p>The most likely cause of this is a database error. Please contact Andrew.Corry@aa.com if you have encountered this error and you are not a contract employee.</p>
                
            </div>
        </div>
    );
}

export default UserNotFound;
