import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { Callback } from './Authorization/Callback';
import Cookies from 'js-cookie'

//delare variables used to authenticate in either dev, stage, or prod environments

const authorizeUrlL = process.env.REACT_APP_LOCAL_AUTH_URL;
const clientIdL = process.env.REACT_APP_LOCAL_IDENTITY_CLIENT_ID;
const redirectUriL = process.env.REACT_APP_LOCAL_REDIRECT_URL + '/callback';

const authorizeUrlD = process.env.REACT_APP_AUTH_URL;
const clientIdD = process.env.REACT_APP_IDENTITY_CLIENT_ID;
const redirectUriD = process.env.REACT_APP_REDIRECT_URL + '/callback';

const authorizeUrlS = process.env.REACT_APP_STAGING_AUTH_URL;
const clientIdS = process.env.REACT_APP_STAGING_IDENTITY_CLIENT_ID;
const redirectUriS = process.env.REACT_APP_STAGING_REDIRECT_URL + '/callback';

const authorizeUrlP = process.env.REACT_APP_PRODUCTION_AUTH_URL;
const clientIdP = process.env.REACT_APP_PRODUCTION_IDENTITY_CLIENT_ID;
const redirectUriP = process.env.REACT_APP_PRODUCTION_REDIRECT_URL + '/callback';

const enhanceAuthorizeUrl = (authorizeUrl, clientId, redirectUri) => {
    return `${authorizeUrl}?response_type=code&client_id=${clientId}&scope=openid&redirect_uri=${redirectUri}`;
    //https://idpstage.aa.com/as/authorization.oauth2?response_type=code&client_id=AttnPrfSys-354654-stage-lxOxU&scope=openid&redirect_uri=https://localhost:7219
};

const App = () => {
    const [initialEmp, setinitialEmp] = useState({});
    const [loading, setLoading] = useState(true);

    //SSCM uses cookies to store information. If the user doesn't have cookies enabled it can cause issues
    //Information being stored using js-cookie is: the starting url for rediretion, the access token returned from single sign on, and if the app is undergoing maitenance or not.
    //You can look at the cookies being used if you log in to SSCM, open the browser console, and enter 'document.cookie'. This is useful for manually getting an access token for testing
    useEffect(() => {
        const login = () => {
            //checks if the user has already logged in and gotten an access token from Callback.js, if it hasn't it sends the user to the callback endpoint
            if (
                (Cookies.get('id_token') === 'undefined' ||
                    Cookies.get('id_token') === undefined) &&
                window.location.href.split('/')[3].substring(0, 14) !== 'callback?code='
            ) {
                Cookies.set('url', window.location.href.split('/')[3], {});
                //checks the domain to know which environment values to use
                //should be reworked to automatically detect environment the code is in without checking the domain name
                if (window.location.origin.includes("localhost")) {
                    console.log("using local url")
                    window.location.replace(
                        enhanceAuthorizeUrl(authorizeUrlL, clientIdL, redirectUriL)
                    );
                } else {
                    if (window.location.origin.includes("test")) {
                        console.log("using test url")
                        window.location.replace(
                            enhanceAuthorizeUrl(authorizeUrlD, clientIdD, redirectUriD)
                        );
                    } else { 
                        if (window.location.origin.includes("stage")) {
                            console.log(enhanceAuthorizeUrl(authorizeUrlS, clientIdS, redirectUriS))
                            console.log("using stage url")
                        window.location.replace(
                            enhanceAuthorizeUrl(authorizeUrlS, clientIdS, redirectUriS)
                        );
                    }
                        else {
                        console.log("using prod url")
                        window.location.replace(
                            enhanceAuthorizeUrl(authorizeUrlP, clientIdP, redirectUriP)
                        );
                    }
                }
                }

                //once the user has logged in and an access token has been saved to the cookies, the load screen is disabled
            } else {
                setLoading(false);
                fetch('message/', {
                    headers: { Authorization: `Bearer ${Cookies.get('id_token')}` },
                })
                    .then((response) => {
                        if (!response.ok) {
                            console.log('Token failed to validate.');
                            setLoading(false);
                            login();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                        login();
                    });
            }
        };

        login();
    }, []);



    //displays a loading screen before sending you to SSO
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '48px',
                }}
            >
                Loading Travel Authorization Site...
            </div>
        );
    }

    //prevents unathenticated users from accessing any route except callback
    if (Cookies.get('id_token') !== 'undefined' && Cookies.get('id_token') !== undefined) {

        /*
        const fetchAuth = fetch(`${apiUrl}/data/initial`, {
            headers: {
                'Authorization': `Bearer ${payload?.access_token}`
            }
        });
        const authResponse = await fetchAuth;
        const authData = await authResponse.json();
        localStorage.setItem('authData', authData);
        Cookies.set('authData', authData);
        */
        //the full webpage is exported here
        if (!loading) {
            return (
                <Layout>
                    <Routes>
                        <Route path="/callback" element={<Callback />} />
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            );
        }

    } else {
        return (
            <Routes>
                <Route path="/callback" element={<Callback />} />
            </Routes>
        );
    }
};

export default App;

